import React from 'react';
import Hero from './HomeHero';
import AboutUs from './AboutUs';
import OurAdvisors from './OurAdvisors';
import Services from './Services';
import Footer from './Footer';
export default function HomePage(props) {

    return (
        <div>
            <Hero />
            <AboutUs />
            <OurAdvisors />
            <Services />
            <Footer />
        </div>
    );
}



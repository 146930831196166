import React from 'react';
import { Grid, Container, Typography, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FooterLogo from './../../assets/images/footerLogo.png';
// import InstagramIcon from '@material-ui/icons/Instagram';
// import TwitterIcon from '@material-ui/icons/Twitter';
const useStyles = makeStyles((theme) => ({
    footer: {
        background: '#000000',
        padding: theme.spacing(10, 0, 10, 0),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(9, 0, 9, 0),
        },
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(8, 0, 8, 0),
        }
    },
    // footerWrapper: {
    //     display: 'flex',
    //     justifyContent: 'space-between',
    //     alignItems: 'center',
    // },
    footerLogo: {
        display: 'block',
        width: '100%',
        maxWidth: '120px',
        [theme.breakpoints.down("sm")]: {
            maxWidth: '108px',
        },
        [theme.breakpoints.down("xs")]: {
            maxWidth: '90px',
        }
    },
    footerSocialWrapper: {
        display: 'flex',
    },
    socialWrapper: {
        background: '#fff',
        padding: theme.spacing(0.5),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: theme.spacing(2),
    },
    footerBottomText: {
        color: '#fff',
        fontWeight: 400,
        marginTop: theme.spacing(5),
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(4),
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(3),
        }
    },
    mailto:{
        fontWeight:700,
        color:"#fff",
        textDecoration:'none',
    }
}));

export default function Footer(props) {
    const classes = useStyles();

    return (
        <div id="footer" className={classes.footer}>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm>
                        {/* <div className={classes.footerWrapper}> */}
                            <img src={FooterLogo} className={classes.footerLogo} alt=""></img>
                            <div className={classes.footerWrapper}>
                            <Typography variant="subtitle2" className={classes.footerBottomText}>We work with creators from all backgrounds, whether<br /> you’re an NFT expert, or complete noob, we’ll get you<br /> digital, known, and funky fresh. </Typography>
                        {/* </div> */}
                            {/* <div className={classes.footerSocialWrapper}>
                                <div className={classes.socialWrapper}>
                                    <InstagramIcon />
                                </div>
                                <div className={classes.socialWrapper}>
                                    <TwitterIcon />
                                </div>
                            </div> */}
                        </div>
                    </Grid>
                    <Grid item xs={12} sm='auto'>
                       <Typography variant='h5'><a href='mailto:info@lunapr.io' className={classes.mailto}>info@lunapr.io</a></Typography>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

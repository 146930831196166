import React from 'react';
import { Grid, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import Feature from './../../assets/images/aboutUsFeature.png';
import Feature from './../../assets/images/aboutUsFeaturenew.png';
// import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    aboutUs: {
        position: 'relative',
        backgroundColor: '#FFFFFF',
        margin: theme.spacing(10, 0, 10, 0),
        [theme.breakpoints.down("sm")]: {
            margin: theme.spacing(9, 0, 9, 0),
        },
        [theme.breakpoints.down("xs")]: {
            margin: theme.spacing(8, 0, 8, 0),
        }
    },
    // aboutUsFeatureWrapper: {
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    // },
    aboutUsFeature: {
        display: 'block',
        // objectFit: 'contain',
        // width: '100%',
        // height:'100%',
        maxWidth: 228,
        maxHeight:250,
        margin: '0 auto',
    },
    aboutUsTitle: {
        fontWeight: 600,
        color: '#1F2024',
    },
    aboutUsBody: {
        fontWeight: 400,
        color: '#1F2024',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
        }
    },
    aboutUsBodyEnd: {
        fontWeight: 700,
        color: '#1F2024',
    },
}));

export default function AboutUs(props) {

    const classes = useStyles();

    return (
        <div id="aboutUs" className={classes.aboutUs}>
            <Container >
                <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12} sm={5} md={4} >
                        {/* <div className={classes.aboutUsFeatureWrapper}> */}
                        <img src={Feature} className={classes.aboutUsFeature} alt=""></img>
                        {/* </div> */}
                        {/* <Typography variant="h1" component="h1" className={classes.homeHeroTitle}></Typography> */}
                        {/* <img src={Feature} className={classes.aboutUsFeature}></img> */}
                    </Grid>
                    <Grid item xs={12} sm={7} md={8} >
                        <Typography variant="h2" className={classes.aboutUsTitle}>ABOUT US</Typography>
                        <Typography variant="subtitle1" className={classes.aboutUsBody}>Albert Einstein once said “True art is characterised by an irresistible urge in the creative artist”. And true art must be protected by blockchain. Welcome to the world of NFT’s - where your art remains yours forever. We work with creators from all backgrounds, whether you’re an NFT expert, or complete noob, we’ll get you digital, known, and funky fresh. </Typography>
                        <Typography variant="h5" className={classes.aboutUsBodyEnd}>Let’s get that floor price up! </Typography>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

import React from 'react';
import { Grid, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AdvisorOne from './../../assets/images/advisor/nikita.png';
import AdvisorTwo from './../../assets/images/advisor/maral.jpg';
import AdvisorThree from './../../assets/images/advisor/yousef.jpg';
import AdvisorFour from './../../assets/images/advisor/nisheta.jpg';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedinIcon from '@material-ui/icons/LinkedIn';

const useStyles = makeStyles((theme) => ({
    ourAdvisors: {
        position: 'relative',
        backgroundColor: '#fff',
        paddingTop: theme.spacing(10),
        margin: theme.spacing(10, 0, 10, 0),
        [theme.breakpoints.down("sm")]: {
            paddingTop: theme.spacing(9),
            margin: theme.spacing(9, 0, 9, 0),
        },
        [theme.breakpoints.down("xs")]: {
            paddingTop: theme.spacing(8),
            margin: theme.spacing(8, 0, 8, 0),
        }
    },
    ourAdvisorsTitle: {
        fontWeight: 600,
        color: '#1F2024',
    },
    ourTeam: {
        marginTop: theme.spacing(10),
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(9),
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(8),
        }
    },
    advisorsCard: {
        position: 'relative',
        height: '100%',
        display: 'flex !important',
        flexDirection: 'column',
        cursor: 'pointer',
        '&:focus': {
            outline: 'none',
        },
        '&:hover': {
            '& $advisorsCardOverlay': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
            }
        }
    },
    advisorsCardImgOverlay: {
        position: 'absolute',
        background: 'linear-gradient(89.61deg, #9235A3 0.3%, #FFAD81 99.63%)',
        mixBlendMode: 'soft-light',
        zIndex: 9,
        width: '100%',
        height: '100%',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    advisorsCardImgWrapper: {
        position: 'relative',
    },
    advisorsCardImg: {
        width: '100%',
        display: 'block',
        objectFit: 'cover',
    },
    advisorsCardOverlay: {
        position: 'absolute',
        background: 'linear-gradient(180deg, rgba(31, 32, 36, 0) 0%, #1F2024 100%)',
        width: '100%',
        height: '100%',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 0.8,
        padding: theme.spacing(2),
        display: 'none',
    },
    advisorsCardTitle: {
        fontWeight: 700,
        color: "rgba(255, 255, 255, 1)",
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            marginBottom: theme.spacing(1.5),
        },
        [theme.breakpoints.down("xs")]: {
            marginBottom: theme.spacing(1),
        }
    },
    advisorsCardSocialWrapper: {
        display: 'flex',
    },
    advisorsCardSocialLogo: {
        background: '#fff',
        padding: theme.spacing(0.5),
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(2),
        '& svg': {
            color: '#000',
        }
    },
    greyBox: {
        backgroundColor: ' #1F2024',
        height: '75%',
        width: '65%',
        position: 'absolute',
        top: 0,
        right: 0,
        [theme.breakpoints.down("sm")]: {
            width: '45%',
        },
        [theme.breakpoints.down("xs")]: {
            width: '25%',
        }
    },
}));

export default function OurAdvisors(props) {

    const classes = useStyles();

    return (
        <div id="ourAdvisors" className={classes.ourAdvisors}>
            <Container >
                <div className={classes.greyBox}></div>
                <Grid container alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="h2" className={classes.ourAdvisorsTitle}>OUR ADVISORS</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={4} alignItems="center" className={classes.ourTeam}>
                    <Grid item xs={12} sm={6} md={3}>
                        <div className={classes.advisorsCard}>
                            <div className={classes.advisorsCardImgWrapper}>
                                <img src={AdvisorOne} alt="" className={classes.advisorsCardImg} />
                            </div>
                            <div className={classes.advisorsCardOverlay}>
                                <Typography variant="h5" className={classes.advisorsCardTitle}>NIKITA SACHDEV</Typography>
                                <div className={classes.advisorsCardSocialWrapper}>
                                    <a href='https://www.linkedin.com/in/nikitasachdev' target="_blank" rel="noreferrer" className={classes.advisorsCardSocialLogo}>
                                        <LinkedinIcon />
                                    </a>
                                    <a href='https://twitter.com/nikichain?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor' target="_blank" rel="noreferrer" className={classes.advisorsCardSocialLogo}>
                                        <TwitterIcon />
                                    </a>
                                    <a href='https://www.instagram.com/nikitasachdev5/?hl=en' target="_blank" rel="noreferrer" className={classes.advisorsCardSocialLogo}>
                                        <InstagramIcon />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <div className={classes.advisorsCard}>
                            <div className={classes.advisorsCardImgWrapper}>
                                <img src={AdvisorTwo} alt="" className={classes.advisorsCardImg} />
                            </div>
                            <div className={classes.advisorsCardOverlay}>
                                <Typography variant="h5" className={classes.advisorsCardTitle}>MARAL NOURI</Typography>
                                <div className={classes.advisorsCardSocialWrapper}>
                                    <a href='https://www.linkedin.com/in/maralnouri' target="_blank" rel="noreferrer" className={classes.advisorsCardSocialLogo}>
                                        <LinkedinIcon />
                                    </a>
                                    <a href='https://twitter.com/MaralNouriii' target="_blank" rel="noreferrer" className={classes.advisorsCardSocialLogo}>
                                        <TwitterIcon />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <div className={classes.advisorsCard}>
                            <div className={classes.advisorsCardImgWrapper}>
                                <img src={AdvisorThree} alt="" className={classes.advisorsCardImg} />
                            </div>
                            <div className={classes.advisorsCardOverlay}>
                                <Typography variant="h5" className={classes.advisorsCardTitle}>YOUSEF AL ALAMI</Typography>
                                <div className={classes.advisorsCardSocialWrapper}>
                                    <a href='https://www.linkedin.com/in/yousef-al-alami/' target="_blank"
                                        rel="noreferrer" className={classes.advisorsCardSocialLogo}>
                                        <LinkedinIcon />
                                    </a>
                                    <a href='https://twitter.com/itsYousefAlami' target="_blank" rel="noreferrer" className={classes.advisorsCardSocialLogo}>
                                        <TwitterIcon />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <div className={classes.advisorsCard}>
                            <div className={classes.advisorsCardImgWrapper}>
                                <img src={AdvisorFour} alt="" className={classes.advisorsCardImg} />
                            </div>
                            <div className={classes.advisorsCardOverlay}>
                                <Typography variant="h5" className={classes.advisorsCardTitle}>NISHETA SACHDEV</Typography>
                                <div className={classes.advisorsCardSocialWrapper}>
                                    <a href='https://www.linkedin.com/in/dr-nisheta-sachdev-33974885' target="_blank"
                                        rel="noreferrer" className={classes.advisorsCardSocialLogo}>
                                        <LinkedinIcon />
                                    </a>
                                    <a href='https://twitter.com/nishoutofwater' target="_blank" rel="noreferrer" className={classes.advisorsCardSocialLogo}>
                                        <TwitterIcon />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

/** @format */

import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
// import HeroGif from './../../assets/images/heroGif.gif';
import Advisory from "./../../assets/images/advisoryVideo.mp4";
// import Typewriter from "typewriter-effect";
// import InstagramIcon from '@material-ui/icons/Instagram';
// import TwitterIcon from '@material-ui/icons/Twitter';
// import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  homeHero: {
    position: "relative",
    backgroundColor: "#e0e0e0",
    padding: theme.spacing(20, 0, 10, 0),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(15, 0, 9, 0),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(15, 0, 8, 0),
    },
  },
  heroVideoWrapper: {
    display: "flex",
    // backgroundColor: '#e2e2e2',
    flexDirection: "column",
    justifyContent: "space-between",
  },
  heroVideo: {
    // backgroundImage: `url(${Feature})`,
    // backgroundSize: "cover",
    // backgroundPosition: "center",
    // backgroundColor: '#F3F3F3',
    width: "100%",
    height: "100%",
    objectFit: "cover",
    display: "block",
  },
  homeHeroTitle: {
    fontWeight: 600,
    color: "#1F2024",
    textAlign: "left",
    maxWidth: 480,
    margin: theme.spacing(0, 0, 5, 0),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 0, 4, 0),
    },
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(0, 0, 3, 0),
    },
  },
  homeHeroSubtitle: {
    fontWeight: 600,
    color: "#1F2024",
    textAlign: "left",
  },
  homeHeroFeature: {
    width: "100%",
    display: "block",
  },
  heroSocialsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  heroSocials: {
    backgroundColor: "#000",
    color: "#ffffff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(1),
    },
  },
}));

export default function HomeHero(props) {
  const classes = useStyles();

  return (
    <div id="home" className={classes.homeHero}>
      <Container>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} sm={12} md={5}>
            <Typography variant="h1" className={classes.homeHeroTitle}>
              Welcome to the world of NFT’s
            </Typography>
            <Typography variant="h2" className={classes.homeHeroSubtitle}>
              Where your art remains yours forever
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={7}>
            {/* <img src={Feature} className={classes.homeHeroFeature} alt=""></img> */}
            <div className={classes.heroVideoWrapper}>
              {/* <img src={HeroGif} className={classes.heroVideo}/> */}
              <div
                dangerouslySetInnerHTML={{
                  __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          src="${Advisory}"
          class="${classes.heroVideo}"
        />,
      `,
                }}></div>
              {/* <div className={classes.heroSocialsWrapper}>
                                <div className={classes.heroSocials}>
                                    <TwitterIcon fontSize="large" />
                                </div>
                                <div className={classes.heroSocials}>
                                    <InstagramIcon fontSize="large" />
                                </div>
                            </div> */}
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
// Welcome to the world of NFT’s - where your art remains yours forever

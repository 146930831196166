import React from 'react';
import { Grid, Typography, Container, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Feature from './../../assets/images/servicesfeature.png';
// import One from './../../assets/images/services/serviceNewOne.png';
// import Two from './../../assets/images/services/serviceNewTwo.png';
// import Three from './../../assets/images/services/serviceNewThree.png';
// import Four from './../../assets/images/services/serviceNewFour.png';
// import Five from './../../assets/images/services/serviceNewFive.png';
// import Six from './../../assets/images/services/serviceNewSix.png';
// import Seven from './../../assets/images/services/serviceNewSeven.png';
// import Eight from './../../assets/images/services/serviceNewEight.png';
// import Nine from './../../assets/images/services/serviceNewNine.png';

import One from './../../assets/images/services/artDirection.jpg';
import Two from './../../assets/images/services/infraAndTokenomics.jpg';
import Three from './../../assets/images/services/pricingStrategy.jpg';
import Four from './../../assets/images/services/maketplaces.jpg';
import Five from './../../assets/images/services/busDevAndPlan.jpg';
import Six from './../../assets/images/services/partnership.jpg';
import Seven from './../../assets/images/services/gotoMarketStrateg.jpg';
import Eight from './../../assets/images/services/utilityAndUseCaseDev.jpg';
import Nine from './../../assets/images/services/marketPos.jpg';

// import OneNh from './../../assets/images/services/artDirectionNh.jpg';
import TwoNh from './../../assets/images/services/infraAndTokenomicsNh.jpg';
import ThreeNh from './../../assets/images/services/pricingStrategyNh.jpg';
import FourNh from './../../assets/images/services/maketplacesNh.jpg';
import FiveNh from './../../assets/images/services/busDevAndPlanNh.jpg';
// import SixNh from './../../assets/images/services/partnershipNh.jpg';
// import SevenNh from './../../assets/images/services/gotoMarketStrategNh.jpg';
// import EightNh from './../../assets/images/services/utilityAndUseCaseDevNh.jpg';
// import NineNh from './../../assets/images/services/marketPosNh.jpg';


const useStyles = makeStyles((theme) => ({
    services: {
        position: 'relative',
        backgroundColor: '#FFF',
        padding: theme.spacing(10, 0, 10, 0),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(9, 0, 9, 0),
        },
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(8, 0, 8, 0),
        },
    },
    servicesContainer: {
        position: 'relative',
        zIndex: 9,
    },
    servicesTitle: {
        fontWeight: 600,
        color: '#fff',
        writingMode: "vertical-rl",
        transform: "rotate(180deg)",
        position: 'relative',
        zIndex: 9,
        margin: '0 auto',
    },
    servicesFeature: {
        width: '100%',
        display: 'block',
        position: 'relative',
        zIndex: 9,
    },
    servicesCard: {
        position: 'relative',
        zIndex: 9,
    },
    servicesCardOne: {
        cursor: 'pointer',
        backgroundImage: `url(${One})`,
        background: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))",
        mixBlendMode: "normal",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: theme.spacing(5),
        boxShadow: "0px 0px 0px 3px #fff inset",
        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        marginBottom: theme.spacing(2),
        width: '100%',
        '&:hover': {
            backgroundImage: `url(${One})`,
            '& $descBtn': {
                display: 'block',
                opacity: 1,
                height: 'auto',
                transition: 'height 5s ease-out, opacity 5s ease-out',
            }
        }
    },
    servicesCardTwo: {
        cursor: 'pointer',
        backgroundImage: `url(${TwoNh})`,
        background: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))",
        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        mixBlendMode: "normal",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: theme.spacing(5),
        boxShadow: "0px 0px 0px 3px #fff inset",
        // filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        marginBottom: theme.spacing(2),
        width: '100%',
        '&:hover': {
            backgroundImage: `url(${Two})`,
            '& $descBtn': {
                display: 'block',
                opacity: 1,
                height: 'auto',
                transition: 'height 5s ease-out, opacity 5s ease-out',
            }
        }
    },
    servicesCardThree: {
        cursor: 'pointer',
        backgroundImage: `url(${ThreeNh})`,
        background: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))",
        mixBlendMode: "normal",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: theme.spacing(5),
        boxShadow: "0px 0px 0px 3px #fff inset",
        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        marginBottom: theme.spacing(2),
        width: '100%',
        '&:hover': {
            backgroundImage: `url(${Three})`,
            '& $descBtn': {
                display: 'block',
                opacity: 1,
                height: 'auto',
                transition: 'height 5s ease-out, opacity 5s ease-out',
            }
        }
    },
    servicesCardFour: {
        cursor: 'pointer',
        backgroundImage: `url(${FourNh})`,
        background: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))",
        mixBlendMode: "normal",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: theme.spacing(5),
        boxShadow: "0px 0px 0px 3px #fff inset",
        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        marginBottom: theme.spacing(2),
        width: '100%',
        '&:hover': {
            backgroundImage: `url(${Four})`,
            '& $descBtn': {
                display: 'block',
                opacity: 1,
                height: 'auto',
                transition: 'height 5s ease-out, opacity 5s ease-out',
            }
        }
    },
    servicesCardFive: {
        cursor: 'pointer',
        backgroundImage: `url(${FiveNh})`,
        background: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))",
        mixBlendMode: "normal",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: theme.spacing(5),
        boxShadow: "0px 0px 0px 3px #fff inset",
        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        marginBottom: theme.spacing(2),
        width: '100%',
        '&:hover': {
            backgroundImage: `url(${Five})`,
            '& $descBtn': {
                display: 'block',
                opacity: 1,
                height: 'auto',
                transition: 'height 5s ease-out, opacity 5s ease-out',
            }
        }
    },
    servicesCardSix: {
        // cursor: 'pointer',
        backgroundImage: `url(${Six})`,
        background: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))",
        mixBlendMode: "normal",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: theme.spacing(5),
        boxShadow: "0px 0px 0px 3px #fff inset",
        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    servicesCardSeven: {
        // cursor: 'pointer',
        backgroundImage: `url(${Seven})`,
        background: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))",
        mixBlendMode: "normal",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: theme.spacing(5),
        boxShadow: "0px 0px 0px 3px #fff inset",
        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    servicesCardEight: {
        // cursor: 'pointer',
        backgroundImage: `url(${Eight})`,
        background: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))",
        mixBlendMode: "normal",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: theme.spacing(5),
        boxShadow: "0px 0px 0px 3px #fff inset",
        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    servicesCardNine: {
        // cursor: 'pointer',
        backgroundImage: `url(${Nine})`,
        background: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))",
        mixBlendMode: "normal",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: theme.spacing(5),
        boxShadow: "0px 0px 0px 3px #fff inset",
        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    servicesCardTitle: {
        fontWeight: 600,
        color: '#fff',
    },
    servicesCardDesc: {
        color: '#fff',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
    },
    descBtn: {
        display: 'none',
        transition: 'height 5s ease-out, opacity 5s ease-out',
        opacity: 0,
        height: 0,
        '& a': {
            textDecoration: 'none',
        }
    },
    outlineBox: {
        width: '473px',
        height: '493px',
        border: '2px solid #1F2024',
        // background: 'linear-gradient(to right, red, purple)',
        position: 'absolute',
        top: '20%',
        left: 32,
        zIndex: 0,
        padding: 2,
        [theme.breakpoints.down("sm")]: {
            width: '373px',
            height: '393px',
            left: 16,
            top: 16,
        },
        [theme.breakpoints.down("xs")]: {
            width: '273px',
            height: '293px',
            left: 8,
            top: 40,
        },
    },
    // insideBox:{
    //     width:'100%',
    //     height:'100%',
    //     // margin:2,
    //     backgroundColor:'#fff',
    // },
    greyBox: {
        backgroundColor: ' #1F2024',
        height: '40%',
        width: '65%',
        position: 'absolute',
        top: '-3%',
        left: 16,
        zIndex: 1,
        [theme.breakpoints.down("sm")]: {
            width: '45%',
            height: '35%',
        },
        [theme.breakpoints.down("xs")]: {
            width: '25%',
            height: '20%',
            top: 6,
        },
    },
    servicesCardQ: {
        fontWeight: 700,
        color: '#fff',
        marginBottom: theme.spacing(1),
    }
}));

export default function Services(props) {

    const classes = useStyles();

    return (
        <div id="services" className={classes.services}>
            <div className={classes.outlineBox}>
                {/* <div className={classes.insideBox}></div> */}
            </div>
            <Container >
                <Grid container spacing={4} className={classes.servicesContainer}>
                    <div className={classes.greyBox}></div>
                    <Grid item xs={2} sm={1} md={1}>
                        <Typography variant="h2" className={classes.servicesTitle}>ADVISORY SERVICES</Typography>
                    </Grid>
                    <Grid item xs={10} sm={2} md={4}>
                        <img src={Feature} className={classes.servicesFeature} alt=""></img>
                    </Grid>
                    <Grid item xs={12} sm={9} md={7} className={classes.servicesCard}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div className={classes.servicesCardOne}>
                                    <Typography variant='h5' className={classes.servicesCardTitle}>ART DIRECTION</Typography>
                                    <div className={classes.descBtn}>
                                        <Typography variant='subtitle2' className={classes.servicesCardDesc}>With a database of hundreds of artists, we are able to provide our clients with works from all mediums including 3D, 2D, digital, traditional, photography and more. We liaise with the artists to ensure clients get the best quality.
                                            <br />
                                            <br />
                                            We work closely with our clients to ensure that the art would be valuable to the holders. As the face of the entire project, the art is the foundation of any collection.
                                        </Typography>
                                        <Typography variant='h6' className={classes.servicesCardQ}> Are you an artist?</Typography>
                                        <a href='mailto:info@lunapr.io'>
                                            <Button color='primary' variant='contained' size='large' disableElevation>Get in Touch!</Button>
                                        </a>
                                    </div>
                                </div>

                                <div className={classes.servicesCardTwo}>
                                    <Typography variant='h5' className={classes.servicesCardTitle}>INFRASTRUCTURE AND TOKENOMICS</Typography>
                                    <div className={classes.descBtn}>
                                        <Typography variant='subtitle2' className={classes.servicesCardDesc}>At The NFT Advisory, we have a team of experts with a background in finance, crypto and blockchain that would guide our clients on the financial plans, structure and planning for the collection. Our team would help you on choosing the right blockchain, the right prices for the collection, allocations to whitelist, public sale, auctions and more.
                                        </Typography>
                                    </div>
                                </div>
                                <div className={classes.servicesCardThree}>
                                    <Typography variant='h5' className={classes.servicesCardTitle}>PRICING STRATEGY</Typography>
                                    <div className={classes.descBtn}>
                                        <Typography variant='subtitle2' className={classes.servicesCardDesc}>
                                            NFTs are all about value to the holders. As the holders ultimately decide the final price of any collection, our team at The NFT Advisory will help ensure that risks are managed, and value is brought to all stakeholders of the project.
                                        </Typography>
                                    </div>

                                </div>
                                <div className={classes.servicesCardFour}>
                                    <Typography variant='h5' className={classes.servicesCardTitle}>MARKETPLACES</Typography>
                                    <div className={classes.descBtn}>
                                        <Typography variant='subtitle2' className={classes.servicesCardDesc}>
                                            Getting into a closed marketplace could make or break any NFT project, and choosing the right marketplace to launch on is crucial for any collection. We will reach out to and connect our clients with the right marketplaces to make sure that the right audiences are captured.
                                        </Typography>
                                        <Typography variant='h6' className={classes.servicesCardQ}>Do you need more creators?</Typography>
                                        <a href='mailto:info@lunapr.io'>
                                            <Button color='primary' variant='contained' size='large' disableElevation> Get in Touch!</Button>
                                        </a>
                                    </div>
                                </div>
                                <div className={classes.servicesCardFive}>
                                    <Typography variant='h5' className={classes.servicesCardTitle}>BUSINESS DEVELOPMENT AND PLANNING</Typography>
                                    <div className={classes.descBtn}>
                                        <Typography variant='subtitle2' className={classes.servicesCardDesc}>
                                            NFTs are not just minted jpegs. They are so much more. With NFTs, the possibilities are endless, and there could be a lot of utility backing them. We at The NFT Advisory would help you bridge those gaps and make sure that your audience gets the most value when it comes to perks. We could help with the idea generation, the brainstorming, and the execution of operations when it comes to building the foundation of your NFT project.
                                        </Typography>
                                        <Typography variant='h6' className={classes.servicesCardQ}>If you are a developer get in touch!</Typography>
                                        <a href='mailto:info@lunapr.io'>
                                            <Button color='primary' variant='contained' size='large' disableElevation>Get in Touch!</Button>
                                        </a>
                                    </div>
                                </div>
                                <div className={classes.servicesCardSix}>
                                    <Typography variant='h5' className={classes.servicesCardTitle}>PARTNERSHIPS</Typography>
                                </div>
                                <div className={classes.servicesCardSeven}>
                                    <Typography variant='h5' className={classes.servicesCardTitle}>GO-TO-MARKET STRATEGY</Typography>
                                </div>
                                <div className={classes.servicesCardEight}>
                                    <Typography variant='h5' className={classes.servicesCardTitle}>UTILITY AND USE-CASE DEVELOPMENT</Typography>
                                </div>
                                <div className={classes.servicesCardNine}>
                                    <Typography variant='h5' className={classes.servicesCardTitle}>MARKET POSITIONING</Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
